import { Snackbar } from "@mui/material";
import { useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";

const CopyToClipboardButton = ({ copyText }) => {
  const [open, setOpen] = useState(false);
  const [state] = useState({
    vertical: "bottom",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(copyText);
  };
  return (
    <>
      <Tooltip title="Copy To Clipboard">
        <ContentCopyIcon onClick={handleClick} className="cursor-pointer" />
      </Tooltip>

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="✅ Copied to clipboard"
        anchorOrigin={{ vertical, horizontal }}
      />
    </>
  );
};
export default CopyToClipboardButton;
