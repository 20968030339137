import "./App.css";
import Login from "./components/Login/Login";
import Dashbaord from "./components/Dashboard/Dashboard";
import Header from "./components/Layout/Header";
import { useLocation, useNavigate } from "react-router-dom";
import Experiment from "./components/Experiment/Experiment";
import Question from "./components/Experiment/Question";
import { ExperimentResults } from "./components/Experiment/ExperimentResults";
import { onAuthStateChanged } from "firebase/auth";
import { useEffect } from "react";
import { auth, getIdToken } from "./Firebase";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const noHeaderPaths = ["/invitations", "/questions"];

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await getIdToken();
        if (idToken) {
          localStorage.setItem("idToken", idToken);
          if (location.pathname === "/") {
            navigate("/dashboard");
          }
        }
      }
    });
  }, [location, navigate]);

  if (currentPath === "/") {
    return <Login />;
  }
  return (
    <div className="App">
      {currentPath !== "/" && !noHeaderPaths.includes(currentPath) && (
        <Header />
      )}
      {currentPath === "/invitations" ? (
        <Experiment />
      ) : currentPath === "/questions" ? (
        <Question />
      ) : currentPath.includes("/experiment/") ? (
        <ExperimentResults />
      ) : (
        <Dashbaord />
      )}
    </div>
  );
}

export default App;
