export const SocialBtn = ({ children, icon, variant, ...rest }) => {
  const variantProps = {
    dark: "bg-dark text-white",
    white: "bg-white text-black",
  };
  return (
    <button
      className={`rounded-full  flex items-center justify-center mx-auto border-2  w-full px-3 py-3 flex-grow mb-5 ${
        variant && variantProps[variant]
      }`}
      {...rest}
    >
      {icon}
      <span className="font-medium text-lg whitespace-nowrap ml-4">
        {children}
      </span>
    </button>
  );
};

export const Btn = ({ className, children, ...rest }) => {
  return (
    <button
      className={`w-28   rounded-xl py-2.5 opacity-90 hover:opacity-100 transition capitalize ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};
