import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Input } from "@mui/material";
import { api } from "../../api";
import Alert from "@mui/material/Alert";
import CopyToClipboardButton from "../UI/CopyToClipboardButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  overflow: "auto",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const InviteModal = ({ experiementName, experimentId }) => {
  const inviteHandler = () => {
    if (participantName) {
      setInviting(true);
      api
        .post("/ml/invite", {
          experimentId,
          participantName,
        })
        .then((res) => {
          console.log(res);
          setInvited(true);
          setInvitationURL(res.data.url);
        })
        .catch((err) => {
          setInvited(false);
          console.log(err);
          alert(err.response.data.message);
        })
        .finally(() => {
          setInviting(false);
        });
    }
  };
  const publicInviteHandler = () => {
    setInviting(true);
    api
      .post("/ml/invite", {
        experimentId,
        participantName: "public",
      })
      .then((res) => {
        console.log(res);
        setInvited(true);
        setInvitationURL(res.data.url + "&self=true");
      })
      .catch((err) => {
        setInvited(false);
        console.log(err);
        alert(err.response.data.message);
      })
      .finally(() => {
        setInviting(false);
      });
  };
  const [inviting, setInviting] = useState(false);
  const [invited, setInvited] = useState(false);
  const [invitationURL, setInvitationURL] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleParticipantNameChange = (e) => {
    setParticipantName(e.target.value);
    setInvited(false);
  };

  return (
    <>
      <Button onClick={handleOpen}>Invite</Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <p className="text-dark">
            Invite To Experiment: <b>{experiementName}</b>
          </p>
          <br></br>
          <Input
            id="participantName"
            placeholder="Participant Name"
            fullWidth
            name="participantName"
            value={participantName}
            onChange={handleParticipantNameChange}
            autoComplete="off"
            variant="standard"
          />
          <br></br>
          <br></br>
          <Button
            variant="outlined"
            color="info"
            onClick={inviteHandler}
            disabled={inviting}
          >
            GET URL
          </Button>{" "}
          &nbsp;
          <Button
            variant="outlined"
            color="info"
            onClick={publicInviteHandler}
            disabled={inviting}
          >
            GET PUBLIC INVITATION URL
          </Button>
          <br></br>
          {invited && (
            <>
              <br></br>
              <Alert severity="success">
                Successfully invited <b>{participantName}</b> to experiment.
                Invitation URL{" "}
                <CopyToClipboardButton
                  copyText={invitationURL}
                ></CopyToClipboardButton>{" "}
                expires in <b>3</b> days &nbsp;
              </Alert>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default InviteModal;
