import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { publicApi } from "../../api";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Logo } from "../../assets/SVG/MainSVG";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

export const Question = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [question, setQuestion] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answering, setAnswering] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleSubmitAnswer = () => {
    if (selectedAnswer && question.id) {
      setAnswering(true);
      publicApi
        .post("/ml/answers", {
          token,
          questionId: question.id,
          answer: selectedAnswer,
        })
        .then((res) => {
          const q = res.data.question;
          if (q) {
            const opts = [...q.options];
            const randomOpts = [];
            while (opts.length > 0) {
              const randomIndex = Math.floor(Math.random() * opts.length);
              randomOpts.push(opts[randomIndex]);
              opts.splice(randomIndex, 1);
            }
            setQuestion({ ...q, options: randomOpts });
            setProgress(
              ((q.totalQuestions - q.remainingQuestions) / q.totalQuestions) *
                100
            );
          } else {
            setQuestion(q);
          }
          setSelectedAnswer(null);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setIsValidToken(false);
        })
        .finally(() => {
          setAnswering(false);
        });
    }
  };

  useEffect(() => {
    if (token) {
      publicApi
        .post("/ml/questions", {
          token,
        })
        .then((res) => {
          setIsValidToken(true);
          console.log(res.data.question);
          const q = res.data.question;
          if (q) {
            const opts = [...q.options];
            const randomOpts = [];
            while (opts.length > 0) {
              const randomIndex = Math.floor(Math.random() * opts.length);
              randomOpts.push(opts[randomIndex]);
              opts.splice(randomIndex, 1);
            }
            setQuestion({ ...q, options: randomOpts });
            setProgress(
              ((q.totalQuestions - q.remainingQuestions) / q.totalQuestions) *
                100
            );
          } else {
            setQuestion(q);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsValidToken(false);
        })
        .finally(() => {
          setLoading(false);
          setSelectedAnswer(null);
        });
    }
  }, [token]);
  let styles = { marginTop: "3%" };
  if (!question) {
    styles = {
      height: "100vh",
    };
  }
  let selectedStyle = {
    border: "3px solid #1976D2",
    borderRadius: "5px",
  };
  return (
    <div className="flex flex-col justify-center items-center" style={styles}>
      {loading && <CircularProgress />}
      {!loading && !isValidToken && (
        <Alert severity="error">Invalid or expired invitation</Alert>
      )}
      {!loading && isValidToken && !question && (
        <>
          <Logo fill="#000000" width={130} height={44} />
          <br />
          <Alert severity="info">
            No more questions, Thank you for your participation!
          </Alert>
        </>
      )}
      {!loading && isValidToken && question && (
        <Box className="max-w-[90%]">
          <br />
          {question.question.split("\n").map((line, index) => (
            <span key={index}>
              <p className="text-dark font-bold">{line}</p>
            </span>
          ))}
          <br />
          <div className="m-auto flex flex-wrap max-w-[100%]">
            {question.options.map((option, index) => (
              <img
                key={option.id}
                src={option.url}
                alt={option.id}
                // className="max-h-[500px] max-w-[400px] m-auto p-5 cursor-pointer"
                className="m-auto p-1 cursor-pointer rounded-2xl"
                onClick={() => setSelectedAnswer(option.id)}
                style={selectedAnswer === option.id ? selectedStyle : null}
              />
            ))}
          </div>
          <br />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSubmitAnswer}
            disabled={answering}
          >
            Next Question
          </Button>
          <br />
          <br />
        </Box>
      )}
    </div>
  );
};

export default Question;
