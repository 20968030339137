import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  overflow: "auto",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Participants = ({ participants }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Button onClick={handleOpen}>Participants</Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          Total Participants: <b>{participants.length}</b>
          <br />
          <br />
          {participants.map((participant, index) => (
            <p key={index}>{participant}</p>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default Participants;
