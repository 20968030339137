import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "../UI/Menu";
import { Logo } from "../../assets/SVG/MainSVG";
import { ProfileThumb } from "../UI/ProfileThumb";
import { signOut } from "../../Firebase";

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const logoutHandler = () => {
    signOut();
    navigate("/");
  };

  return (
    <>
      <div className="flex justify-between items-center flex-wrap whitespace-nowrap py-4 2xl:py-5 pr-8 pl-6 border-b border-lightGray">
        <Link to="/dashboard">
          <Logo fill="#000000" width={130} height={44} />
        </Link>
        <div className="flex items-center ">
          <div className="md:flex items-center hidden">
            <span
              className={`capitalize mr-8 hover:text-dark active:text-dark font-[700]`}
            >
              Dashboard
            </span>
          </div>
          <div className="relative cursor-pointer">
            <div onClick={() => setIsProfileOpen(!isProfileOpen)}>
              <ProfileThumb
                //   loading={loading}
                profileThumb={localStorage.getItem("image")}
              />
            </div>
            <Menu
              isOpen={isProfileOpen}
              close={() => setIsProfileOpen(false)}
              position="right"
              style={{ width: "12rem" }}
            >
              <li
                className="py-2 pb-4 px-4 hover:bg-gray-200"
                tabIndex={0}
                onClick={logoutHandler}
              >
                <span className="text-sm">🗡️</span> Logout
              </li>
            </Menu>
          </div>
        </div>
      </div>
      {!location.pathname.startsWith("/create") && (
        <div className="w-full py-3 bg-white z-[9999] fixed bottom-0 md:hidden flex items-center justify-evenly border-t  transform  transition-transform">
          <span
            className={`capitalize text-sm ml-4 mr-4 active:text-dark font-[700]`}
          >
            Dashboard
          </span>
        </div>
      )}
    </>
  );
};

export default Header;
