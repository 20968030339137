import React from "react";
import { UserIcon } from "../../assets/SVG/MainSVG";

const renderImgSize = (size) => {
  switch (size) {
    case "sm":
      return "w-10 h-10";
    case "md":
      return "w-12 h-12";
    default:
      return "w-10 h-10";
  }
};
export const ProfileThumb = ({ profileThumb, className, size = "sm" }) => {
  return (
    <div
      className={`img ${renderImgSize(
        size
      )} ${className} flex items-center justify-center `}
    >
      {profileThumb ? (
        <img
          src={profileThumb}
          className="w-full h-full rounded-full "
          alt="profile"
        />
      ) : (
        <UserProfilePlaceHolder size={size} />
      )}
    </div>
  );
};
export const UserProfilePlaceHolder = ({ size = "sm" }) => {
  return (
    <span
      className={`flex justify-center items-center bg-[rgba(0,0,0,.3)] text-white  rounded-full ${renderImgSize(
        size
      )}`}
    >
      {<UserIcon />}
    </span>
  );
};
