import { CSSTransition } from "react-transition-group";

export const Menu = ({ children, isOpen, close, position = "left", style }) => {
  const renderPosition = {
    left: "left-0 menu--left",
    right: "right-0 menu--right",
  };

  return (
    <div style={{ display: !isOpen ? "none" : "unset" }}>
      <CSSTransition in={isOpen} timeout={300} classNames="show" unmountOnExit>
        <ul
          onClick={close}
          className={`absolute bg-white   top-[calc(100%+10px)]  w-max min-w-[10rem] z-[99]  text-dark3 font-semibold rounded-lg shadow-lg border-[.33px] menu ${renderPosition[position]}  `}
          style={style}
        >
          {children}
        </ul>
      </CSSTransition>
    </div>
  );
};
