import { Container } from "../Layout/Container";
import Page from "../Layout/Page";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Context } from "../../Context";
import BiotechIcon from "@mui/icons-material/Biotech";
import { InviteModal } from "../Experiment/Invite";
import { Participants } from "../Experiment/Participants";
import { CreateExperiment } from "../Experiment/CreateExperiment";
import { useEffect } from "react";
import { api } from "../../api";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const Dashboard = () => {
  const [searchResults, setSearchResults] = useState(null);
  const [experiments, setExperiments] = useState([]);
  const navigate = useNavigate();
  const handleShowResults = (experimentId) => {
    navigate(`/experiment/${experimentId}`);
  };
  const getExperiments = async () => {
    api
      .get("/ml/experiments")
      .then((res) => {
        console.log(res.data.experiments);
        setExperiments(res.data.experiments);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getExperiments();
  }, []);

  const handleDeleteExperiment = (experimentId) => {
    const confirm = window.confirm(
      "Are you sure you want to delete this experiment? This action cannot be undone."
    );
    if (confirm) {
      api
        .delete(`/ml/experiments/${experimentId}`)
        .then((res) => {
          console.log(res);
          getExperiments();
        })
        .catch((err) => {
          console.log(err);
          alert(err.response);
        })
        .finally(() => {});
    }
  };

  return (
    <Context.Provider value={[searchResults, setSearchResults]}>
      <Page title="Dashboard">
        <Container>
          <div className="flex flex-wrap justify-center content-center">
            {experiments.map((experiment) => (
              <Card
                className="relative"
                key={experiment.id}
                sx={{ maxWidth: 345, margin: 3, minWidth: 345, minHeight: 240 }}
              >
                <div className="absolute right-0">
                  <Button
                    onClick={() => handleDeleteExperiment(experiment.id)}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </div>
                <BiotechIcon
                  fontSize="large"
                  color="primary"
                  className="mt-5"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {experiment.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {experiment.description} <br></br>
                    Created On:{" "}
                    {new Date(experiment.createdAt).toLocaleDateString("fr-CA")}
                  </Typography>
                </CardContent>
                <div className="flex flex-wrap justify-center content-center mb-5">
                  <CardActions>
                    <InviteModal
                      experiementName={experiment.title}
                      experimentId={experiment.id}
                    ></InviteModal>
                    <Participants
                      participants={experiment.participants}
                    ></Participants>
                    <Button
                      size="small"
                      onClick={() => handleShowResults(experiment.id)}
                    >
                      Results
                    </Button>
                  </CardActions>
                </div>
              </Card>
            ))}
            <Card
              sx={{ maxWidth: 345, margin: 3, minWidth: 345, minHeight: 240 }}
            >
              <div className="h-full grid place-items-center">
                <div>
                  <AddIcon fontSize="large" color="primary" />
                  <CardActions>
                    <CreateExperiment />
                  </CardActions>
                </div>
              </div>
            </Card>
          </div>
        </Container>
      </Page>
    </Context.Provider>
  );
};

export default Dashboard;
