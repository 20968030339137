import axios from "axios";
import { getIdToken } from "./Firebase";

// `http://127.0.0.1:4999`
export const api = axios.create({
  baseURL: `https://staging.starryai.com`,
});

export const publicApi = axios.create({
  baseURL: `https://staging.starryai.com`,
});

const refreshToken = () => {
  const firebaseToken = getIdToken();
  if (firebaseToken) {
    firebaseToken
      .then((idToken) => {
        // console.log(idToken);
        if (idToken) {
          console.log("token is valid");
          localStorage.setItem("idToken", idToken);
        } else {
          console.log("no token");
        }
      })
      .catch((error) => {
        localStorage.removeItem("idToken");
        console.warn("[REFRESH_TOKEN_ERROR]", error);
        alert("[REFRESH_TOKEN_ERROR]", error);
      });
  } else {
    localStorage.removeItem("idToken");
    console.log("no token");
  }
};

api.interceptors.request.use(
  (config) => {
    refreshToken();
    const idToken = localStorage.getItem("idToken");
    if (idToken) {
      config.headers.Authorization = idToken;
      return config;
    } else {
      return new Promise((resolve) => {
        setTimeout(() => {
          const idToken = localStorage.getItem("idToken");
          if (idToken) {
            config.headers.Authorization = idToken;
            resolve(config);
          } else {
            resolve(config);
          }
        }, 3000);
      });
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403 && !localStorage.getItem("idToken")) {
      alert("[Authentication ERROR]", error);
      localStorage.clear();
      window.location.href = "/";
      return api(error.config);
    }
    return Promise.reject(error);
  }
);
