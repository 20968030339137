import { Container } from "../Layout/Container";
import Page from "../Layout/Page";
import { SocialBtn } from "../UI/Btn";
import { AppleIcon, CloseIcon, Logo } from "../../assets/SVG/MainSVG";
import { signInWithGoogle, signInWithApple } from "../../Firebase";
import googleIcon from "../../assets/images/google_logo.png";
import { CSSTransition } from "react-transition-group";
import { useNavigate } from "react-router-dom";
import { api } from "../../api";

const Login = () => {
  const navigate = useNavigate();

  const googleHandler = () => {
    signInWithGoogle().then((idToken) => {
      if (idToken) {
        console.log("token is valid");
        api
          .get("/ml/user")
          .then((res) => {
            localStorage.setItem("name", res.data.name);
            localStorage.setItem("image", res.data.image);
            navigate("/dashboard");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("no token");
      }
    });
  };

  const appleHandler = () => {
    signInWithApple().then((idToken) => {
      if (idToken) {
        console.log("token is valid");
        api
          .get("/ml/user")
          .then((res) => {
            localStorage.setItem("name", res.data.name);
            localStorage.setItem("image", res.data.image);
            navigate("/dashboard");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("no token");
      }
    });
  };

  return (
    <Page title="ML Tools Login">
      <div className="imgWapper min-h-screen  bg-center bg-no-repeat bg-cover bg-fixed">
        <Container>
          <div className="flex justify-center">
            <div className=" text-center mt-6 md:mt-10 2xl:mt-20 mb-16">
              <div className="flex justify-center">
                <Logo fill="white" width={205} height={69} />
              </div>
              <p className="text-xl text-white font-sans font-normal tracking-wider mt-2">
                ML Tools
              </p>
            </div>
          </div>
          <div className="flex justify-center ">
            <div className="2xl:w-2/5 lg:w-1/2  sm:w-4/5 w-full bg-white py-10 px-4 rounded-xl mb-10 shadow">
              <h2 className="font-medium text-3xl 2xl:text-3xl text-center text-dark mb-8">
                Log In
              </h2>
              <CSSTransition timeout={300} classNames="show" unmountOnExit>
                <div className="relative py-2 text-center bg-red-200 mb-4 rounded flex justify-center items-center ">
                  <button className="absolute top-3 right-3">
                    <CloseIcon width={16} height={16} fill="red" />
                  </button>
                </div>
              </CSSTransition>
              <div className="btns flex flex-col items-center md:w-3/4 mx-auto">
                <SocialBtn
                  icon={
                    <img
                      src={googleIcon}
                      alt="google icon"
                      width={23}
                      height={23}
                    />
                  }
                  variant="white"
                  onClick={googleHandler}
                >
                  Continue with Google
                </SocialBtn>
                <SocialBtn
                  icon={<AppleIcon />}
                  variant="dark"
                  onClick={appleHandler}
                >
                  Continue with Apple
                </SocialBtn>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Page>
  );
};

export default Login;
