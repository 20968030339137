import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { api } from "../../api";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListItem from "@mui/material/ImageListItem";

export const ExperimentResults = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const experimentId = currentPath.split("/experiment/")[1];
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [experiment, setExperiment] = useState({});
  let questionResults = {};
  if (experiment && experiment.questions) {
    experiment.questions.forEach((question) => {
      const totalAnswers = question.answers.length;
      if (!questionResults[question.id]) {
        questionResults[question.id] = {};
      }
      question.options.forEach((option) => {
        const totalOptionAnswers = question.answers.filter(
          (answer) => answer.answer == option.id
        ).length;
        questionResults[question.id][option.id] =
          (totalOptionAnswers / totalAnswers) * 100;
      });
    });
  }

  useEffect(() => {
    if (experimentId) {
      api
        .get(`/ml/experiment/${experimentId}`)
        .then((res) => {
          setIsValidToken(true);
          const exp = res.data.experiment;
          setExperiment(exp);
          console.log(exp);
        })
        .catch((err) => {
          console.log(err);
          setIsValidToken(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [experimentId]);

  let answersMap = {};
  let answers = {};
  let totalAnswers = 0;
  if (experiment && experiment.questions) {
    experiment.questions.forEach((question) => {
      const options = question.options;
      options.forEach((option) => {
        if (!answersMap[option.id]) {
          answersMap[option.id] = option.description;
        }
      });
      let qanswers = question.answers;
      qanswers.forEach((qanswer) => {
        if (answers[qanswer.answer]) {
          answers[qanswer.answer]++;
        } else {
          answers[qanswer.answer] = 1;
        }
      });
      totalAnswers = Object.values(answers).reduce((a, b) => a + b, 0);
    });
    answers = Object.keys(answers).reduce((acc, key) => {
      acc[answersMap[key]] = answers[key];
      return acc;
    }, {});
  }

  return (
    <div className="flex flex-col justify-center items-center p-5">
      {loading && <CircularProgress />}
      {!loading && !isValidToken && (
        <Alert severity="error">Experiment Not Found</Alert>
      )}
      {!loading && isValidToken && (
        <Box>
          <Typography variant="h5">{experiment.title}</Typography>
          <br></br>
          <Typography variant="h6">Overall Results</Typography>
          <Box className="flex flex-col justify-center">
            <div className="m-auto flex flex-wrap max-w-[100%] justify-center items-center">
              {Object.keys(answers).map((key) => (
                <div className="p-3" key={key}>
                  <p>{key}</p>
                  <br></br>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-flex",
                    }}
                  >
                    <CircularProgress
                      variant="determinate"
                      value={(answers[key] / totalAnswers) * 100}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                      >
                        {`${Math.round((answers[key] / totalAnswers) * 100)}%`}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              ))}
            </div>
          </Box>

          {experiment.questions.map((question) => {
            return (
              <div
                key={question.id}
                className="border-solid border-2 m-5 rounded"
              >
                <Box>
                  <br></br>
                  <Typography variant="h6">{question.question}</Typography>
                  <Typography variant="body1">
                    Responses: <b>{question.answers.length}</b>
                  </Typography>
                  <div className="m-auto flex flex-wrap max-w-[100%] justify-center items-center">
                    {question.options.map((option) => {
                      return (
                        <ImageListItem key={option.id}>
                          <img
                            src={option.url}
                            alt={option.id}
                            className="max-h-[500px] max-w-[400px] m-auto p-5"
                          />
                          <ImageListItemBar
                            title={
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                }}
                              >
                                <CircularProgress
                                  variant="determinate"
                                  value={
                                    questionResults[question.id][option.id]
                                  }
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: "absolute",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                  >
                                    {`${Math.round(
                                      questionResults[question.id][option.id]
                                    )}%`}
                                  </Typography>
                                </Box>
                              </Box>
                            }
                            subtitle={
                              <p className="mt-2 break-word mx-auto max-w-[350px] break-all	whitespace-normal">
                                {option.description}
                              </p>
                            }
                            position="below"
                          />
                        </ImageListItem>
                      );
                    })}
                  </div>
                </Box>
                <br />
              </div>
            );
          })}
        </Box>
      )}
    </div>
  );
};

export default ExperimentResults;
