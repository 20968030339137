import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { publicApi } from "../../api";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { Logo } from "../../assets/SVG/MainSVG";
import { useNavigate } from "react-router-dom";
import { Input } from "@mui/material";

export const Experiment = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const selfInvite = searchParams.get("self") || false;
  const [selfName, setSelfName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [experiment, setExperiment] = useState({});
  const navigate = useNavigate();

  const handleJoinExperiment = () => {
    navigate(`/questions?token=${token}`);
  };

  const handleSelfJoin = () => {
    if (selfName) {
      setLoading(true);
      publicApi
        .post("/ml/selfInvite", {
          token,
          experimentId: experiment.id,
          participantName: selfName,
        })
        .then((res) => {
          setIsValidToken(true);
          console.log(res.data);
          window.location.href = res.data.url;
        })
        .catch((err) => {
          console.log(err);
          setIsValidToken(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (token) {
      publicApi
        .post("/ml/invitation", {
          token,
        })
        .then((res) => {
          setIsValidToken(true);
          setExperiment(res.data.experiment);
          console.log(res.data.experiment);
        })
        .catch((err) => {
          console.log(err);
          setIsValidToken(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selfInvite, token]);

  if (selfInvite) {
    return (
      <div className="flex flex-col h-screen justify-center items-center">
        <Logo fill="#000000" width={130} height={44} />
        <br />
        {loading && <CircularProgress />}
        {!loading && !isValidToken && (
          <Alert severity="error">Invalid or expired invitation</Alert>
        )}
        {!loading && isValidToken && (
          <>
            <p className="text-dark">You have been invited to an experiment</p>
            <p className="text-dark">
              Please enter your name below to continue
            </p>
            <br />
            <Input
              placeholder=""
              onChange={(e) => setSelfName(e.target.value)}
              value={selfName}
              variant="outlined"
              autoFocus
            />
            <br />
            <Button variant="outlined" color="primary" onClick={handleSelfJoin}>
              Continue
            </Button>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <Logo fill="#000000" width={130} height={44} />
      <br />
      {loading && <CircularProgress />}
      {!loading && !isValidToken && (
        <Alert severity="error">Invalid or expired invitation</Alert>
      )}
      {!loading && isValidToken && (
        <Box>
          <p className="text-dark">You have been invited to</p>
          <p className="text-dark font-bold">{experiment.title}</p>
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleJoinExperiment}
          >
            Join Experiment
          </Button>
          <br />
          <br />
          <Alert severity="info">
            Your progress will be saved automatically
          </Alert>
        </Box>
      )}
    </div>
  );
};

export default Experiment;
