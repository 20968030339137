import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Input } from "@mui/material";
import { api } from "../../api";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "auto",
  overflow: "auto",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const CreateExperiment = () => {
  const createHandler = () => {
    try {
      const validJson = JSON.parse(experimentJson);
      console.log(validJson);
    } catch (e) {
      alert("Invalid JSON");
    }
    if (experimentName && experimentJson) {
      setCreating(true);
      api
        .post("/ml/experiments", {
          experimentName,
          description,
          experimentJson,
        })
        .then((res) => {
          console.log(res);
          setCreated(true);
          window.location.href = `/dashboard`;
        })
        .catch((err) => {
          setCreated(false);
          console.log(err);
          alert(err.response.data.message);
        })
        .finally(() => {
          setCreating(false);
        });
    }
  };
  const [creating, setCreating] = useState(false);
  const [created, setCreated] = useState(false);
  const [experimentName, setExperimentName] = useState(null);
  const [description, setDescription] = useState(null);
  const [experimentJson, setExperimentJson] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Create Experiment</Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <p className="text-dark">New Experiment</p>
          <br></br>
          <Input
            id="experimentName"
            placeholder="Experiment Name"
            fullWidth
            name="experimentName"
            value={experimentName}
            onChange={(e) => setExperimentName(e.target.value)}
            autoComplete="off"
            variant="standard"
          />
          <br></br>
          <br></br>
          <Input
            id="experimentDescription"
            placeholder="Description"
            fullWidth
            name="experimentDescription"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoComplete="off"
            variant="standard"
          />
          <br></br>
          <br></br>
          <a
            href="../../assets/sample.json"
            target="_blank"
            className="text-dark underline"
          >
            sample with JSON schema
          </a>
          <Input
            id="experimentJson"
            placeholder="Questions JSON"
            fullWidth
            name="experimentJson"
            value={experimentJson}
            onChange={(e) => setExperimentJson(e.target.value)}
            autoComplete="off"
            variant="standard"
            multiline
            rows={10}
          />
          <br></br>
          <br></br>
          <Button
            variant="outlined"
            color="info"
            onClick={createHandler}
            disabled={creating}
          >
            Create
          </Button>
          <br></br>
          {created && (
            <>
              <br></br>
              <Alert severity="success">Experiment Created Successfully</Alert>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CreateExperiment;
